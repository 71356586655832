import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';

import {ServerResponse} from '../../interfaces/server-response';
import {LoginResponseToBackOffice} from './interfaces/login-response-to-back-office';
import {LoginRequestToBackOffice} from './interfaces/login-request-to-back-office';
import {EnvironmentService} from '../../services/env/environment.service';
import {CabinetLoggedResponse} from '../../interfaces/cabinet-logged-response';
import {DeveloperLoggedResponse} from './interfaces/developer-logged-response';
import {LoginResponseToDevCabinet} from './interfaces/login-response-to-dev-cabinet';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  oauthPath = this.environmentService.getPathForOauthRequests();
  backOfficePath = this.environmentService.getPathForBackOfficeRequests();
  developerPath = this.environmentService.getPathForDeveloperRequests();

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService
  ) { }

  /**
   * Логин Oauth
   * @param data информация для отправки на сервер
   */
  loginOauth(data: FormData): Observable<ServerResponse> {
    return this.http.post<any>(this.oauthPath + '/login', data, { withCredentials: true });
  }

  /**
   * Логин в бэк-офис
   * @param data информация для отправки на сервер
   */
  loginToBackOffice(data: LoginRequestToBackOffice): Observable<LoginResponseToBackOffice> {
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    return this.http.post<any>(this.backOfficePath + '/cabinetlogin', data, {headers, withCredentials: true});
  }

  /**
   * Логин в кабинет разработчика
   * @param data информация для отправки на сервер
   */
  loginToDevCabinet(data: LoginRequestToBackOffice): Observable<LoginResponseToDevCabinet> {
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    return this.http.post<any>(this.developerPath + '/login', data, {headers, withCredentials: true});
  }

  /**
   * Проверка, залогинен ли пользователь в кабинет разработчика
   */
  isDeveloperLogged(): Observable<DeveloperLoggedResponse> {
    return this.http.get<any>(this.developerPath + '/logged', {withCredentials: true});
  }
}
