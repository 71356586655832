import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EnvironmentService} from '../../services/env/environment.service';
import {Observable} from 'rxjs';
import {SetNewPasswordRequest} from './interfaces/set-new-password-request';
import {SetNewPasswordResponse} from './interfaces/set-new-password-response';

@Injectable({
  providedIn: 'root'
})
export class SetNewPasswordService {

  backOfficePath = this.environmentService.getPathForBackOfficeRequests();

  constructor(private http: HttpClient,
              private environmentService: EnvironmentService) {}


  setNewPassword(data: SetNewPasswordRequest): Observable<SetNewPasswordResponse> {
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    return this.http.post<any>(this.backOfficePath + '/cabrestorepassword/confirm', data, { headers, withCredentials: true });
  }

}
